@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}